//封装axios

import axios from 'axios'
import { getToken, removeToken } from './token'
// import '@/mock/user.js'

//实例化
const http = axios.create({
  // baseURL: 'http://localhost:8080/api/v1/',   //不使用mock时，注释掉这一行即可
  // baseURL: 'http://localhost:8080/api/v1/', //开发环境
  baseURL: '/api/v1/', //生产环境
  timeout: 5000
})

//添加请求拦截器
http.interceptors.request.use((config) => {
  // 请求时，传入token
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

//添加响应拦截器
http.interceptors.response.use((response) => {
  // 2xx范围内的状态码都会触发该函数
  if (response.data.code === 1008) {
    window.location.href = '/#/403'
    // history.push('/#/403')
  } else if (response.data.code === 1011) {
    removeToken()
    window.location.href = '/#/login'
  }
  // else if (response.data.code === 1002) {
  //   window.location.href = '/#/500'
  // history.push('/#/500')
  // }
  return response.data
}, (error) => {
  //超出2xx范围的状态码都会触发该函数
  if (error.response.status === 401) {
    // 跳回到登录，reactRouter默认状态下，并不支持在组件之外完成路由的跳转，需要自己来实现
    window.location.href = '/#/login'
    // history.push('/#/login')

  }
  // else if (error.response.status === 500) {
  //   window.location.href = '/#/500'
  // }
  return Promise.reject(error)
})

export { http }
