import { makeAutoObservable } from 'mobx';
import { http } from '@/utils';

class SignupStore {
  constructor() {
    makeAutoObservable(this);
  }
  responseData = {};
  signup = async ({ username, password, email, repassword }) => {
    const res = await http.post('/signup', {
      username,
      password,
      email,
      repassword,
    });
    this.responseData = res;
  };
}

export default SignupStore;
