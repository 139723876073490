import CryptoJS from 'crypto-js';

//封装localstorage存取token

const key = 'myblog-token'
const role_key = "siteUUID"


// 加密函数
const encrypt = (str, salt) => {
  const encrypted = CryptoJS.AES.encrypt(str, salt).toString();
  return encrypted;
}

// 解密函数
const decrypt = (encryptedStr, salt) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedStr, salt).toString(CryptoJS.enc.Utf8);
  return decrypted;
}

const setRole = (role) => {
  return window.localStorage.setItem(role_key, encrypt(role, key + "ilearning.top"))
}

const getRole = () => {
  const encRole = window.localStorage.getItem(role_key)
  if (encRole !== null) {
    return decrypt(window.localStorage.getItem(role_key), key + "ilearning.top")
  }
}

// 存token
const setToken = (token) => {
  return window.localStorage.setItem(key, token) //存操作的结果
}

//取token
const getToken = () => {
  return window.localStorage.getItem(key)
}
//删token
const removeToken = () => {
  window.localStorage.removeItem(role_key)
  return window.localStorage.removeItem(key) //删操作的结果
}

export {
  setToken,
  getToken,
  removeToken,
  setRole,
  getRole
}
