import { makeAutoObservable } from 'mobx'
import { http } from '@/utils'

class ArticleStore {
  constructor() {
    makeAutoObservable(this)
  }

  articleTypeData = {
    code: 1000,
    msg: '',
    typelist: [],
  }

//   获取文章类型
  getArticleType = async () => {
    const res = await http.get('/article/articleTypes')
    this.articleTypeData.code = res.code
    this.articleTypeData.msg = res.msg
    this.articleTypeData.typelist = res.data
  }


  articles = {
    code: 1000,
    msg: '',
    articlelist: [],
  }
//   获取所有文章
  getAllArticles = async () => {
    const res = await http.get('/article/allArticles')
    this.articles.code = res.code
    this.articles.msg = res.msg
    this.articles.articlelist = res.data
  }

  typeArticles = {
    code: 1000,
    msg: '',
    articlelist: [],
  }
//   获取某一类型下的所有文章
  getArticlesByTypeID = async ({ typeID }) => {
    const res = await http.get('/article/listType/' + typeID)
    this.typeArticles.code = res.code
    this.typeArticles.msg = res.msg
    this.typeArticles.articlelist = res.data
  }

  // 根据文章id获取文章的详细信息
  articleDetails = {
    code: 1000,
    msg: '',
    details: {},
  }
  getArticleDetails = async ({ articleID }) => {
    const res = await http.get('/article/article/' + articleID)
    this.articleDetails.code = res.code
    this.articleDetails.msg = res.msg
    this.articleDetails.details = res.data
  }

  // 创建新的文章
  code = 1000
  msg = ''
  newArticleID = 0
  details = []
  publishArticle = async ({ values, mdContent, mdHtml }) => {
    const res = await http.post('/article/article', {
      title: values.title,
      type_id: values.typeId,
      summary: values.summary,
      content: mdHtml,
      markdown: mdContent
    })
    this.code = res.code
    this.msg = res.msg
    this.newArticleID = res.data
    window.location.href = '/#/editArticle?blog_id=' + this.newArticleID
  }

  updateArticle = async ({ values, mdContent, mdHtml, articleID }) => {
    const res = await http.put('/article/article/' + articleID, {
      title: values.title,
      type_id: values.typeId,
      summary: values.summary,
      content: mdHtml,
      markdown: mdContent
    })
    this.code = res.code
    this.msg = res.msg
    this.details = res.data
  }

  updateFidleForArticle = async ({ field, articleID, content }) => {
    let res = null
    if (field === 'title') {
      res = await http.put('/article/article/' + articleID, {
        title: content
      })
    } else if (field === 'summary') {
      res = await http.put('/article/article/' + articleID, {
        summary: content
      })
    } else if (field === 'articleType') {
      res = await http.put('/article/article/' + articleID, {
        type_id: content
      })
    } else if (field === 'favor') {
      res = await http.put('/article/article/' + articleID, {
        favor_count: content
      })
    } else if (field === 'reply') {
      res = await http.put('/article/article/' + articleID, {
        reply_count: content
      })
    } else if (field === 'public') {
      res = await http.put('/article/article/' + articleID, {
        public_status: 'public'
      })
    } else if (field === 'private') {
      res = await http.put('/article/article/' + articleID, {
        public_status: 'private'
      })
    }
    this.code = res.code
    this.msg = res.msg
    this.details = res.data
  }

  deleteArticle = async ({ articleID }) => {
    const res = await http.delete('/article/article/' + articleID, {})
    this.code = res.code
    this.msg = res.msg
    this.details = res.data
  }

  addArticleType = async ({ typeName }) => {
    const res = await http.post('/article/articleTypes', {
      type_name: typeName
    })
    this.code = res.code
    this.msg = res.msg
  }

  updateArticleType = async ({ articleTypeID, typeName }) => {
    const res = await http.put('/article/articleTypes/' + articleTypeID, {
      type_name: typeName
    })
    this.code = res.code
    this.msg = res.msg
  }

  deleteArticleType = async ({ articleTypeID }) => {
    const res = await http.delete('/article/articleTypes/' + articleTypeID, {})
    this.code = res.code
    this.msg = res.msg
  }

}

export default ArticleStore
