import { makeAutoObservable } from 'mobx'
import { http } from '@/utils'

class FavorStore {
  constructor() {
    makeAutoObservable(this)
  }

  code = 1000
  msg = ''
  data = []
  
  addArticleFavor = async ({ articleID }) => {
    const res = await http.post('favor/favors/' + articleID)
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }
}

export default FavorStore
