import { makeAutoObservable } from 'mobx'

import { http } from '@/utils'

class ForgotpwStore {
  constructor() {
    makeAutoObservable(this)
  }

  responseData = {}
  commitUserInfo = async ({ username, email }) => {
    const res = await http.post('/forgotpw', {
      username,
      email,
    })
    this.responseData = res
  }
  resetPassword = async ({ userid, timestamp, hash, password, repassword }) => {
    const res = await http.post(
      '/resetselfpw?uid=' +
      userid +
      '&timestamp=' +
      timestamp +
      '&hash=' +
      hash,
      {
        password,
        repassword,
      },
    )
    this.responseData = res
  }
}

export default ForgotpwStore
