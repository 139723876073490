//1.判断token是否存在
//2.如果存在，直接正常渲染
//3.如果不存在，重定向到登录路由

import {getRole, getToken} from '@/utils'
import {Navigate, useLocation} from 'react-router-dom'
import {useStore} from '@/store'

//高阶组件：把一个组件当成另外一个组件的参数传入
//然后通过一定的判断，返回新的组件

// 定义必须管理员才能访问的路径列表
const adminPath = ['/articlesmanage', '/usersmanage', '/replymanage', '/articletypesmanage', '/publish', '/editArticle', '/settings']

function AuthComponent({children}) {
  const {userStore} = useStore()
  const location = useLocation()
  const isToken = getToken()
  const role = getRole()
  if (isToken) {
    // 判断：如果不是博主，而且请求的路径是只能博主访问的，则跳转到403
    if (userStore.userRole !== '博主') {
      if (role !== '博主') {
        let flag = false
        adminPath.map((path) => {
          if (path === location.pathname) {
            flag = true
          }
        })
        if (flag === true) {
          return <Navigate to="/403" replace/>
        }
      }
    }
    return <>{children}</>
  } else {
    return <Navigate to="/login" replace/>
  }
}

export {AuthComponent}
