//登录模块
import {makeAutoObservable} from 'mobx'
import {getToken, http, removeToken, setRole, setToken} from '@/utils'

class LoginStore {
  token = getToken() || '' // 先尝试取本地的，取不到则为空
  responseData = {}

  constructor() {
    makeAutoObservable(this)
  }

  //登录
  getToken = async ({username, password}) => {
    const res = await http.post('/login', {
      username,
      password,
    })
    // 存入token
    this.responseData = res
    if (this.responseData.code === 1000) {
      this.token = this.responseData.data.access_token + ' ' + this.responseData.data.refresh_token
      //存入localStoreage
      setToken(this.token)
      // 只有博主权限才存role
      if (res.data.role === '博主') {
        setRole(res.data.role)
      }
    }

  }

  //清除token，退出登录
  loginOut = () => {
    this.token = ''
    removeToken()
    http.post('/logout')
  }
}

export default LoginStore
