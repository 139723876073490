//把所有的模块做统一处理
//导出一个统一的方法useStore

import LoginStore from './login.Store'
import UserStore from '@/store/user.Store'
import SignupStore from '@/store/signup.Store'
import ForgotpwStore from '@/store/forgotpw.Store'
import ArticleStore from '@/store/article.Store'
import SettingStore from '@/store/setting.Store'
import CalendarStore from '@/store/calendar.Store'
import FavorStore from '@/store/favor.Store'
import React from 'react'

import { configure } from 'mobx'
import ReplyStore from '@/store/reply.Store'

configure({
  enforceActions: 'never',
})

class RootStore {
  constructor() {
    this.loginStore = new LoginStore()
    this.userStore = new UserStore()
    this.signupStore = new SignupStore()
    this.forgotStore = new ForgotpwStore()
    this.articleStore = new ArticleStore()
    this.replyStore = new ReplyStore()
    this.settingStore = new SettingStore()
    this.calendarStore = new CalendarStore()
    this.favorStore = new FavorStore()
  }
}

//实例化根

const rootStore = new RootStore()

//导出useStore context
const context = React.createContext(rootStore)
const useStore = () => React.useContext(context)

export { useStore }
