import {makeAutoObservable} from 'mobx'
import {http} from '@/utils'
import React from 'react'

class UserStore {
  userInfo = {}
  userRole = null
  userName = '游客'
  code = 1000
  msg = ''
  profile = {}

  constructor() {
    makeAutoObservable(this)
  }

  getUserInfo = async () => {
    //调用接口获取数据
    const res = await http.get('/userinfo')
    this.userInfo = res.data
    this.userRole = this.userInfo.role
    this.userName = this.userInfo.username
    this.code = res.code
    this.msg = res.msg
  }

  lockUser = async (userid) => {
    const res = await http.post('/lock/' + userid)
    this.code = res.code
    this.msg = res.msg
  }

  unLockUser = async (userid) => {
    const res = await http.post('/unlock/' + userid)
    this.code = res.code
    this.msg = res.msg
  }

  resetUserPassword = async ({user_id, password, repassword}) => {
    const res = await http.post('/resetpw', {user_id, password, repassword})
    this.code = res.code
    this.msg = res.msg
  }

  deleteUser = async (user_id) => {
    const res = await http.delete('/delete/' + user_id)
    this.code = res.code
    this.msg = res.msg
  }

  changeMyPassword = async ({origin_password, new_password, re_new_password}) => {
    const res = await http.post('/changepw', {origin_password, new_password, re_new_password})
    this.code = res.code
    this.msg = res.msg
  }

  getUserProfile = async () => {
    const res = await http.get('/profile')
    this.code = res.code
    this.msg = res.msg
    this.profile = res.data
  }

  updateEmail = async ({email}) => {
    const res = await http.post('/updateProfile', {email})
    this.code = res.code
    this.msg = res.msg
  }
}

export default UserStore
