import { makeAutoObservable } from 'mobx'
import { http } from '@/utils'

class ReplyStore {
  constructor() {
    makeAutoObservable(this)
  }

  code = 1000
  msg = ''
  data = []

  updateStatus = async ({ replyID, status }) => {
    const res = await http.put('/reply/setStatus', {
      'status': status,
      'id': replyID
    })
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }

  deleteReply = async ({ replyID }) => {
    const res = await http.delete('/reply/deleteReply/' + replyID)
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }
  createReply = async ({ articleID, content }) => {
    const res = await http.post('/reply/articleReplies', {
      'content': content,
      'blog_id': articleID
    })
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }

  updateReply = async ({ replyID, content }) => {
    const res = await http.put('/reply/articleReplies', {
      'content': content,
      'id': replyID
    })
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }
  deleteUserReply = async ({ replyID }) => {
    const res = await http.delete('/reply/articleReplies/' + replyID)
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }

}

export default ReplyStore
