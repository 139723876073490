import '@/App.css'
import React, { lazy, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Space, Spin, } from 'antd'
import { AuthComponent } from '@/components/AuthComponent'
// 按需导入路由组件
const Login = lazy(() => import('@/pages/Login'))
const Layout = lazy(() => import('@/pages/Layout'))
const Signup = lazy(() => import('@/pages/Signup'))
const ForgotPW = lazy(() => import('@/pages/ForgotPW'))
const Home = lazy(() => import('@/pages/Home'))
const ArticlesManage = lazy(() => import('@/pages/ArticlesManage'))
const UsersManage = lazy(() => import('@/pages/UsersManage'))
const ArticleDetail = lazy(() => import('@/pages/ArticleDetail'))
const ArticleTypesManage = lazy(() => import('@/pages/ArticleTypesManage'))
const ReplyManage = lazy(() => import('@/pages/ReplyManage'))
const ArticlePublish = lazy(() => import('@/pages/ArticlePublish'))
const ArticleEdit = lazy(() => import('@/pages/ArticleEdit'))
const Settings = lazy(() => import('@/pages/Settings'))
const About = lazy(() => import('@/pages/About'))
const Profile = lazy(() => import('@/pages/Profile'))
const Calendar = lazy(() => import('@/pages/Calendar'))
const Error403 = lazy(() => import('@/pages/Errors/403'))
const Error404 = lazy(() => import('@/pages/Errors/404'))
const Error500 = lazy(() => import('@/pages/Errors/500'))


function App() {


  return (
    //路由配置
    <HashRouter>
      {/*<HistoryRouter history={history}>*/}
      {/* 懒加载配置 */}
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              marginTop: 200,
            }}
          >
            <Space size="middle">
              <Spin size="large"/>
            </Space>
          </div>
        }
      >
        <div className="App">
          <Routes>
            {/* 创建路由path和组件的对应关系 */}
            {/* Layout是需要鉴权处理的 */}
            <Route path="/" element={<Layout/>}>
              {/* 嵌套路由， index代表默认路由 */}
              <Route index element={<Home/>}></Route>
              <Route path="/articlesmanage" element={<AuthComponent><ArticlesManage/></AuthComponent>}></Route>
              <Route path="/usersmanage" element={<AuthComponent><UsersManage/></AuthComponent>}></Route>
              <Route path="/replymanage" element={<AuthComponent><ReplyManage/></AuthComponent>}></Route>
              <Route path="/articletypesmanage"
                     element={<AuthComponent><ArticleTypesManage/></AuthComponent>}></Route>
              <Route path="/publish" element={<AuthComponent><ArticlePublish/></AuthComponent>}></Route>
              <Route path="/editArticle" element={<AuthComponent><ArticleEdit/></AuthComponent>}></Route>
              <Route path="/settings" element={<AuthComponent><Settings/></AuthComponent>}></Route>
              <Route path="/about" element={<About/>}></Route>
              <Route path="/profile" element={<AuthComponent><Profile/></AuthComponent>}></Route>
              <Route path="/calendar" element={<AuthComponent><Calendar/></AuthComponent>}></Route>
              <Route path="/articledetail" element={<ArticleDetail/>}></Route>
            </Route>

            <Route path="/login" element={<Login/>}></Route>
            <Route path="/signup" element={<Signup/>}></Route>
            <Route path="/forgotpw" element={<ForgotPW/>}></Route>
            <Route path="/403" element={<Error403/>}></Route>
            <Route path="/404" element={<Error404/>}></Route>
            <Route path="/500" element={<Error500/>}></Route>
            <Route path="*" element={<Error404/>}></Route>
          </Routes>
        </div>
      </Suspense>
      {/*</HistoryRouter>*/}
    </HashRouter>
  )
}

export default App
