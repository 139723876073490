import { makeAutoObservable } from 'mobx'
import React from 'react'
import { http } from '@/utils'

class SettingStore {
  code = 1000
  msg = ''
  data = {}

  constructor() {
    makeAutoObservable(this)
  }

  updateSetting = async (values) => {
    const res = await http.post('/settings/setting', values)
    this.code = res.code
    this.msg = res.msg
    this.data = res.data
  }

}


export default SettingStore
