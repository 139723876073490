import { makeAutoObservable } from 'mobx'
import { http } from '@/utils'

class CalendarStore {
  code = 1000
  error = ''
  msg = ''
  data = []

  constructor() {
    makeAutoObservable(this)
  }

  addCalendar = async ({ calendars, curDate }) => {
    const doDate = curDate.format('YYYY-MM-DD')
    const res = await http.post('/calendar/calendars', {
      'calendarContent': calendars,
      'doneDate': doDate
    })
    this.code = res.code
    this.data = res.data
    this.msg = res.msg
    if (this.msg !== 'success' && this.msg !== null) {
      this.error = this.msg
    }
  }

  updateCalendar = async ({ calendarID }) => {
    const res = await http.put('/calendar/calendars/' + calendarID)
    this.code = res.code
    this.data = res.data
    this.msg = res.msg
    if (this.msg !== 'success' && this.msg !== null) {
      this.error = this.msg
    }
  }

  deleteCalendar = async ({ calendarID }) => {
    const res = await http.delete('/calendar/calendars/' + calendarID)
    this.code = res.code
    this.data = res.data
    this.msg = res.msg
    if (this.msg !== 'success' && this.msg !== null) {
      this.error = this.msg
    }
  }
}


export default CalendarStore
